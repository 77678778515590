import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import HomeIdeation from "../../components/pages/home/HomeIdeation";
import HomeWireframe from "../../components/pages/home/HomeWireframe";
import EnterpriseBannerNoCTA from "../../components/pages/enterprise/EnterpriseBannerNoCTA";
import Shoutout from "../../components/pages/signup/Shoutout";
import Content from "../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Themes = ({ data }) => (
  <Layout>
    <Meta
      title="UI Design Themes | Discover Uizard UX/UI Themes | Uizard"
      description="Turn inspirations into design assets. Create themes automatically from any source of inspirations or import your existing design assets. Read more."
      url="/themes/"
    />

    <Content>
      <EnterpriseBannerNoCTA
        tagline="Uizard UI Themes"
        headline="Design like a pro"
        description="Create themes easily and apply new styles to your project in seconds.
        Enable anyone in your organization to create beautiful interface mockups with a consistent style!"
        imageA={data.bannerImageA.childImageSharp}
        imageTopB={data.bannerTopImageB.childImageSharp}
        imageBottomB={data.bannerBottomImageB.childImageSharp}
        tagAsTitle={true}
      />
    </Content>

    <HomeIdeation
      tagline="Design tokens"
      headline="Turn inspirations into design assets"
      description="
      Create themes automatically from any source of inspirations or import your existing design system."
      imageAlt="Uizard AI can extract components and styling properties from images."
      imageA={data.themeGenerationImageA.childImageSharp}
      imageB={data.themeGenerationImageB.childImageSharp}
      videoName="uizard-theme-generation"
      link="/design-assistant/"
    />

    <HomeWireframe
      tagline="Low-fidelity mockup"
      headline="Turn wireframe mode in one click"
      description="Whenever you need to focus on structure and content and avoid lengthly conversations about colors and details."
      imageA={data.wireframeImageA.childImageSharp}
      imageB={data.wireframeImageB.childImageSharp}
      imageAlt="Uizard supports wireframe mode to render designs in low-fidelity."
      videoName="uizard-low-fi-mode"
      link="/wireframing/"
    />

    <HomeIdeation
      tagline="Theming"
      headline="Swap between themes in one click"
      description="Apply beautiful themes to your projects in seconds.
      Explore different inspirations or update your design to reflect your company's latest design guidelines."
      imageAlt="Uizard supports theming to render designs in high-fidelity."
      videoName="uizard-theme-switcher"
      imageA={data.ideationImageA.childImageSharp}
      imageB={data.ideationImageB.childImageSharp}
    />

    <ContentExtended>
      <Shoutout
        headline="No tutorial needed"
        description="Demonstrate your ideas in seconds"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Themes;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(relativePath: { eq: "enterprise/banner-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2074, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerTopImageB: file(
      relativePath: { eq: "enterprise/banner-top-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerBottomImageB: file(
      relativePath: { eq: "enterprise/banner-bottom-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageA: file(relativePath: { eq: "home/styling-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2120, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageB: file(relativePath: { eq: "home/styling-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1290, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ideationImageA: file(relativePath: { eq: "home/ideation-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    ideationImageB: file(relativePath: { eq: "home/ideation-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageA: file(relativePath: { eq: "home/wireframe-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageB: file(relativePath: { eq: "home/wireframe-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageA: file(
      relativePath: { eq: "home/uizard-theme-generation-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageB: file(
      relativePath: { eq: "home/uizard-theme-generation-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
